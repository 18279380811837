import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";

// utils-imports
import { randomUUID } from "../../../services/randomUUID";

// component-imports
import FormHeading from "../../../UI/FormHeading/FormHeading";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import { TotpModal } from "./TotpModal/TotpModal";

// context-imports
import OnBoardCompanyProdContext from "../../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";

// api-config-imports
import { APIConfig } from "../../../services/apiConfiguration";
import axios from "axios";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// styles import
import "./OnBoardCredentialsProd.scss";
import { checkerMail } from "../../../utilities/emailMakerChekerEmail";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const OnBoardCredentialsProd = () => {
  // ^ context
  const { companyID, toCompanyDetails, kybData, sendEmailReport } = useContext(
    OnBoardCompanyProdContext
  );

  // const dmoReferenceID = randomUUID();

  // & btn submit state
  const [enableDoneBtn, setEnableDoneBtn] = useState(false);
  const [disableSendCredentials, setDisableSendCredentials] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });

  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (formData, values, action) => {
    //& Send Email Report before TOTP
    sendEmailReport(kybData["_id"]);

    const payload = {
      grant_type: "admin_credentials",
      email: checkerMail,
      // email,
      google_user_token: google_user_token,
      permission_required: "company_cred_check",
      // source: "ADMIN",
      // consent: true,
      // reference_id: randomUUID(),
      // google_user_token,
      // email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl + "/v2/auth/token",
      // paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            request_urn: res?.data?.request_urn || null,
          }));
        }
        // Open OTP Modal
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  //* Send Credentials API
  const sendCredentialsAPI = () => {
    setDisableSendCredentials(true);
    setEnableDoneBtn(false);

    APIConfig.API_Client.post(
      paAPIEndpoints.SEND_ONBOARDING_CREDENTIALS.baseUrl +
        paAPIEndpoints.SEND_ONBOARDING_CREDENTIALS.endpoint,
      {
        reference_id: randomUUID(),
        company_id: companyID,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        setEnableDoneBtn(true);
        setDisableSendCredentials(true);

        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Credentials sent Successfully"}
          />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        setEnableDoneBtn(false);
        setDisableSendCredentials(false);

        // * snackbar for error
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  return (
    <div className="pa-onboard-summary-details">
      <FormHeading headingText="Confirmation" />

      <div className="pa-summary-action-wrapper">
        <p className="pa-summary-action-label">
          Company has been onboarded successfully.
        </p>
        <div className="pa-summary-button-container">
          <button
            className={`formik-btn-submit ${
              !disableSendCredentials ? "active" : "disabled"
            }`}
            disabled={disableSendCredentials}
            onClick={submitHandler}
          >
            {"Generate Credentials"}
          </button>
        </div>
      </div>

      <div className="pa-done-button-container">
        <button
          className={`formik-btn-submit ${
            enableDoneBtn ? "active" : "disabled"
          }`}
          type="button"
          onClick={toCompanyDetails}
          disabled={!enableDoneBtn}
        >
          Done
        </button>
      </div>

      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCallback={sendCredentialsAPI}
        />
      ) : null}
    </div>
  );
};

export default OnBoardCredentialsProd;
