import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//ui-component-import
import FormHeading from "../../../UI/FormHeading/FormHeading";
import FormTitle from "../../../UI/FormTitle/FormTitle";
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import VerifiedAccountBlock from "./VerifiedAccountBlock/VerifiedAccountBlock";
import { TotpModal } from "./TotpModal/TotpModal";

// utils imports
import { randomUUID } from "../../../services/randomUUID";
import { REGEXP } from "../../../utilities/validators/inputValidators";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";
import { checkerMail } from "../../../utilities/emailMakerChekerEmail";

//context-imports
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";
import OnBoardCompanyProdContext from "../../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";

//API-imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";

// styles import
import "./SettlementAccountProd.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const initialValues = {
  // consumer_urn: "",
  name: "",
  account_number: "",
  ifsc: "",
};

const { laAccountRegex, settlementAccountNameRegex, ifscRegex } = REGEXP;

const validationSchema = Yup.object().shape({
  account_number: Yup.string()
    .matches(laAccountRegex, "Must be a valid account number")
    .required("Account number is required"),
  ifsc: Yup.string()
    .matches(ifscRegex, "Must be a valid IFSC code")
    .required("IFSC code is required"),
  name: Yup.string()
    .matches(
      settlementAccountNameRegex,
      "Name must be alphanumeric with spaces"
    )
    .min(3, "Name must be at least 3 characters")
    .required("Name is required"),
});

const SettlementAccountProd = () => {
  // ^ context
  const {
    toDMO,
    consumerURN,
    toCompanyDetails,
    companyID,
    toSummary,
    //& KYB
    kybData,
    phoneNumber,
  } = useContext(OnBoardCompanyProdContext);

  //& KYB detail-- for single data
  const [formKybData, setFormKybData] = useState(null);

  //& KYB
  useEffect(() => {
    if (formKybData) {
      formik.setFieldValue("account_number", formKybData?.account_number || "");
      formik.setFieldValue("ifsc", formKybData?.branch_ifsc || "");
      formik.setFieldValue("name", formKybData?.bank_account_name || "");

      formik.setFieldTouched("account_number", true);
      formik.setFieldTouched("ifsc", true);
      formik.setFieldTouched("name", true);
    }
  }, [formKybData]);

  // display keys
  const selectedKeys = [
    "message",
    "beneficiaryName",
    "bankReferenceNumber",
    "transactionStatus",
  ];

  // & state
  const [showOnFailedSettlementAccount, setShowOnFailedSettlementAccount] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      grant_type: "admin_credentials",
      email: checkerMail,
      // email,
      google_user_token: google_user_token,
      permission_required: "settlement_approval_check",
      // // Old
      // source: "ADMIN",
      // consent: true,
      // reference_id: randomUUID(),
      // google_user_token,
      // email,
    };

    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl + "/v2/auth/token",
      // paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            request_urn: res?.data?.request_urn || null,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  // Settlement Account API
  const configureSettlementAccount = () => {
    let settlementAccountSetupPayload = {
      reference_id: randomUUID(),
      action: "ADD",
      consumer_urn: consumerURN,
      account_number: formik.values.account_number,
      ifsc: formik.values.ifsc.toUpperCase(),
      name: formik.values.name,
      provider_code: "yesb",
      is_master_settlement_account: true,
    };

    setShowOnFailedSettlementAccount(false);

    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.baseUrl +
        paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.endpoint,
      settlementAccountSetupPayload,
      { cancelToken: source.token }
    )
      .then((response) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        // * To DMO step of onboarding
        toDMO();

        //   action.resetForm();
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );

        setShowOnFailedSettlementAccount(true);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      submitHandler();
    },
  });

  //& KYB fetchStateDetails
  const fetchKYBDetails = (phoneNumber) => {
    APIConfig.API_Client.post(
      paAPIEndpoints.FETCH_KYB_DETAILS_ONBOARDING.baseUrl +
        paAPIEndpoints.FETCH_KYB_DETAILS_ONBOARDING.endpoint,
      { phone_number: phoneNumber },
      { cancelToken: source.token }
    )
      .then((response) => {
        // Set empty full data
        setFormKybData(null);

        // Handle formKybData based on response type
        if (Array.isArray(response.data)) {
          if (response.data.length > 1) {
            // Multi-data
            setFormKybData(
              response?.data?.find((item) => item._id === kybData["_id"]) ||
                null
            );
          } else if (response.data.length === 1) {
            setFormKybData(response.data[0]); // Single data
          } else {
            setFormKybData(null);
            console.warn("No KYB data available:", response.data);
          }
        } else {
          setFormKybData(null);
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //& KYB
  useEffect(() => {
    fetchKYBDetails(phoneNumber);
  }, [phoneNumber, kybData]);

  useEffect(() => {
    fetchKYBDetails(phoneNumber);
  }, []);

  return (
    <>
      {formKybData?.bank_account_verified === true ? (
        <form onSubmit={formik.handleSubmit}>
          <div className="ui-form-details">
            {/* form-main */}
            <div className="ui-form-content">
              {formKybData?.bank_account_verified === true ? (
                <VerifiedAccountBlock
                  verificationDetails={
                    formKybData?.bank_account_verification_response_json
                  }
                  selectedKeys={selectedKeys}
                />
              ) : null}

              {/* form-heading */}
              <FormHeading headingText="Fill account details to continue" />

              {/* COMPANY DETAILS */}
              <FormTitle titleText="Add Settlement Account" />

              {/* # 1 */}
              <div className="ui-form-inputs-section">
                <TextFieldInput
                  id="consumer_urn"
                  name="consumer_urn"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={consumerURN || ""}
                  touched={formik.touched.consumer_urn}
                  error={formik.errors.consumer_urn}
                  placeholder="Enter Consumer URN"
                  label="Consumer URN"
                  required={true}
                  disabled={true}
                />

                <TextFieldInput
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  touched={formik.touched.name}
                  error={formik.errors.name}
                  placeholder="Enter Name"
                  label="Name"
                  required={true}
                  disabled={false}
                />
              </div>

              {/* # 2 */}
              <div className="ui-form-inputs-section">
                <TextFieldInput
                  id="account_number"
                  name="account_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.account_number}
                  touched={formik.touched.account_number}
                  error={formik.errors.account_number}
                  placeholder="Enter Account Number"
                  label="Account Number"
                  required={true}
                  disabled={false}
                />

                <TextFieldInput
                  id="ifsc"
                  ifsc="ifsc"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ifsc.toUpperCase()}
                  touched={formik.touched.ifsc}
                  error={formik.errors.ifsc}
                  placeholder="Enter IFSC"
                  label="IFSC"
                  required={true}
                  disabled={false}
                />
              </div>
            </div>
            {/* form-main-ends */}

            {/* submit-button-starts */}
            {formKybData?.bank_account_verified === true ? (
              <div className="ui-settlement-button-container">
                <button
                  className={`${
                    formik.isValid && formik.dirty && !formik.isSubmitting
                      ? "active"
                      : ""
                  }`}
                  type="submit"
                  disabled={!formik.isValid || formik.isSubmitting}
                  //! onClick={goToModuleProviderPA}
                >
                  {formik.isSubmitting ? "Loading..." : "Submit"}
                </button>
              </div>
            ) : null}
            {/* submit-button-ends */}
          </div>
        </form>
      ) : null}

      {!formKybData?.bank_account_verified ? (
        <div className="ui-settlement-button-container container-flex">
          <p className="">
            <span className="fail-highlight">Account verification failed</span>,{" "}
            <br /> please add the settlement account via the dedicated section.
          </p>
          <button className={`${"active"}`} type="button" onClick={toDMO}>
            Skip this step
          </button>
        </div>
      ) : null}

      {/* TOTP */}
      {showTotpModal && (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitAfterTotp={configureSettlementAccount}
          formik={formik}
          verificationDetails={
            formKybData?.bank_account_verification_response_json
          }
          cancelledChequeUpload={formKybData?.cancelled_cheque_upload}
          selectedKeys={selectedKeys}
        />
      )}

      {/* Summary backup btn */}
      {/* consumerURN */}
      {showOnFailedSettlementAccount ? (
        <div className="ui-settlement-button-container container-flex">
          <p className="error-message">
            Settlement Account addition failed. Please generate credentials by
            clicking on Generate/Send Credentials and <br /> add the Settlement
            Account separately.
          </p>
          <button className={`${"active"}`} type="button" onClick={toSummary}>
            Generate Credentials
          </button>
        </div>
      ) : null}

      {/* Multi Attempt */}
      {/* <MultiAttemptPA companyID={companyID}/> */}
    </>
  );
};

export default SettlementAccountProd;
