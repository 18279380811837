import React from "react";

import "./VerifiedSettlementAccountBlock.scss";
import "../../../UI/TextField/TextField.scss";

const VerifiedSettlementAccountBlock = ({ submitHandler }) => {
  return (
    <div className="verify-settlement-container">
      <p className="label-text">Successfully Verified Bank Account</p>

      <div className="ui-button-container">
        <button
          className={`formik-btn-submit ${"active"}`}
          type="submit"
          onClick={submitHandler}
        >
          Configure Settlement Account
        </button>
      </div>
    </div>
  );
};

export default VerifiedSettlementAccountBlock;
