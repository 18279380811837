import React, { useRef, useState } from "react";
import ReactDom from "react-dom";
// component styles
import CloseIcon from "@material-ui/icons/Close";
// styles imports
import "./ReportsSuccessModal.scss";
import CopyButton from "../../UI/CopyButton/CopyButton";

export const ReportsSuccessModal = ({ setShowModal, message, url }) => {
  const [responseState, setResponseState] = useState([]);

  function extractReportId(url) {
    const match = url.match(/\/([^\/]+)$/);
    return match ? match[1] : null;
  }

  return ReactDom.createPortal(
    <div className="container">
      <div className="reports-modal">
        {/* close modal icon */}
        <button className="close-modal-btn" onClick={() => setShowModal(false)}>
          <CloseIcon />
        </button>

        {/* modal header */}
        <div className="reports-modal-heading">
          <img
            className="success-status-icon"
            src="/images/success-tick.svg"
            alt="success"
          />
          <p>Report Generated Successfully</p>
        </div>

        {/* Display the success message */}
        <div>
          <p className="reports-modal-subtitle">
            The report has been generated and will be sent to the provided email
            address.
          </p>

          {url ? (
            <div className="reports-response-list-item">
              <div className="field">Execution ID: </div>
              <div className="value">{extractReportId(url) || "-"}</div>
              <CopyButton text={extractReportId(url)} className="mis-copybtn" />
            </div>
          ) : null}

          {/* {url && (
            <p className="download-container">
              Download your report{" "}
              <a href={url} target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
          )} */}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
