import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import "./EditableTextFieldInput.scss";
import ToolTip from "../ToolTip/ToolTip";
import { useKYB } from "../../contexts/CustomerManagementProdContext/KYBContext";
import OnBoardCompanyProdContext from "../../contexts/CustomerManagementProdContext/OnBoardCompanyProdContext";
import SnackbarMessage from "../../components/SnackbarMessage/SnackbarMessage";

const EditableTextFieldInput = ({
  id = "initial",
  label = "Label",
  name,
  ammendFieldName = "",
  onChange,
  onBlur,
  value, //initial value
  touched,
  error,
  placeholder,
  type = "text",
  required,
  disabled,
  maxLength,
  isToolTip,
  //   ex
  // apiEndpoint = "https://workflow.decpay.in/webhook/update/pa_onboarding",
  // apiEndpoint = "https://staging.workflow.decpay.in/webhook/update/pa_onboarding",
  recallAPI,
  kybID = "",
}) => {
  // ^ context
  const { phoneNumber, setKybData, fetchKYBDetails } = useContext(
    OnBoardCompanyProdContext
  );
  // KYB
  // const { data, loading, fetchKYBDetails } = useKYB();

  let workFlowEndpoint =
    process.env.REACT_APP_ENV_NAME === "production"
      ? "https://workflow.decpay.in/webhook/update/pa_onboarding"
      : "https://staging.workflow.decpay.in/webhook/update/pa_onboarding";

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.put(workFlowEndpoint, {
        agent_name: JSON.parse(localStorage.getItem("user")).name, //
        agent_email: JSON.parse(localStorage.getItem("user")).email,
        field_name: ammendFieldName,
        updated_value: value,
        kyb_id: kybID,
      });
      if (response.status === 200) {
        recallAPI(phoneNumber);
        // fetchKYBDetails(phoneNumber);
        setIsEditing(false);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response?.data?.message} />,
          document.getElementById("snackbar")
        );
      } else {
        console.error("Failed to update value.");
      }
    } catch (error) {
      console.error("API request failed:", error);
      ReactDOM.render(
        <SnackbarMessage msgtype="Error" msg={error.response?.data?.message} />,
        document.getElementById("snackbar")
      );
    }
  };

  return (
    <div className="ui-form-edit-field-container">
      <div class="ui-form-edit-field">
        {/* label */}
        <label className="ui-form-edit__label" htmlFor={id}>
          {label}
          {/* tooltip and required */}
          <ToolTip
            text={isToolTip}
            requiredField={required}
            isToolTip={isToolTip}
          >
            {isToolTip && (
              <div className="ui-info-icon">
                <img src="/images/info-icon.svg" alt="" />
              </div>
            )}
          </ToolTip>
        </label>

        {!isEditing ? (
          <>
            {/* prefill value */}
            {/* <div className="ui-form-edit-field__prefill-value">{value}</div> */}
            <div className="ui-form-edit-field__input">
              <input
                id={id}
                name={name}
                type={type}
                onChange={onChange}
                onBlur={onBlur}
                className={`ui-form-input-box ${
                  touched && error ? "input-error" : ""
                }`}
                placeholder={placeholder}
                value={value}
                disabled={true}
                maxLength={maxLength}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                aria-autocomplete="none"
              />
            </div>
            {/* edit action */}
            <div className="field__actions">
              <div
                className="edit-field action-btn"
                onClick={handleEditClick}
                aria-label="Edit"
              >
                {/* 🚀 */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-pencil"
                >
                  <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
                  <path d="m15 5 4 4" />
                </svg>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* input field */}
            <div className="ui-form-edit-field__input">
              <input
                id={id}
                name={name}
                type={type}
                onChange={onChange}
                onBlur={onBlur}
                className={`ui-form-input-box ${
                  touched && error ? "input-error" : ""
                }`}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                maxLength={maxLength}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                aria-autocomplete="none"
              />
            </div>
            {/* actions */}
            <div className="field__actions">
              <div
                className="save-field action-btn"
                onClick={handleSaveClick}
                aria-label="Save"
              >
                {/* ✅ */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-check"
                >
                  <path d="M20 6 9 17l-5-5" />
                </svg>
              </div>
              <div
                className="cancel-field action-btn"
                onClick={handleCancelClick}
                aria-label="Cancel"
              >
                {/* ❌ */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-x"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditableTextFieldInput;
