import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import { REGEXP } from "../../utilities/validators/inputValidators";
import { APIConfig } from "../../services/apiConfiguration";
import paAPIEndpoints from "../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// utils-import
import { randomUUID } from "../../services/randomUUID";

//component-import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "./ResponseDetails/ResponseDetails";
import VerifiedSettlementAccountBlock from "./VerifiedSettlementAccountBlock/VerifiedSettlementAccountBlock";
import { TotpModal } from "./TotpModal/TotpModal";
import ToggleCategory from "../../UI/ToggleCategory/ToggleCategory";

//styles-import
import "../../UI/TextField/TextField.scss";
import { checkerMail } from "../../utilities/emailMakerChekerEmail";

const initialValues = {
  name: "",
  action: "",
  account_number: "",
  consumer_urn: "",
  settlement_account_urn: "",
  ifsc: "",
  is_master_settlement_account: false,
};

const {
  laAccountRegex,
  consumerUrnRegex,
  settlementAccountNameRegex,
  ifscRegex,
} = REGEXP;
// const validationSchema = Yup.object({
//   action: Yup.object().shape({
//     value: Yup.string().required("Please select an option"),
//     label: Yup.string().required("Please select an option"),
//   }),
//   account_number: Yup.string()
//     .matches(accountNumberRegex)
//     .required("Account Number is required"),
//   consumer_urn: Yup.string()
//     .matches(consumerUrnRegex)
//     .required("Consumer URN is required"),
//   name: Yup.string().matches(nameFieldRegex).required("Name is required"),
//   ifsc: Yup.string()
//     .matches(ifscRegex, "Invalid IFSC code")
//     .required("IFSC code is required"),
// });

const validationSchema = Yup.object({
  action: Yup.object().shape({
    value: Yup.string().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  // Define initial schema for fields under ADD action
  account_number: Yup.string().when("action", {
    is: (val) => val && val.label === "ADD",
    then: () =>
      Yup.string()
        .matches(laAccountRegex, "Invalid account number")
        .required("Account Number is required"),
  }),
  name: Yup.string().when("action", {
    is: (val) => val && val.label === "ADD",
    then: () =>
      Yup.string()
        .matches(settlementAccountNameRegex, "Invalid name format")
        .required("Name is required"),
  }),
  ifsc: Yup.string().when("action", {
    is: (val) => val && val.label === "ADD",
    then: () =>
      Yup.string()
        .matches(ifscRegex, "Invalid IFSC code")
        .required("IFSC code is required"),
  }),
  consumer_urn: Yup.string()
    .matches(consumerUrnRegex, "Invalid Consumer URN")
    .required("Consumer URN is required"),
  settlement_account_urn: Yup.string().when("action", {
    is: (val) => val && val.label === "DEACTIVATE",
    then: () =>
      Yup.string()
        .matches(consumerUrnRegex, "Invalid Settlement URN")
        .required("Settlement URN is required"),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SettlementAccount = () => {
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);

  // &  state's for Response block
  const [validateResponseDetails, setValidateResponseDetails] = useState(null);
  const [showValidateResponseDetails, setShowValidateResponseDetails] =
    useState(false);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  let actionDropDownValues = [
    {
      value: "ADD",
      label: "ADD",
    },
    {
      value: "DEACTIVATE",
      label: "DEACTIVATE",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [dataToVerify, setDataToVerify] = useState({
    request_urn: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  const settlementAccountAction = (formik) => {
    const { values, action } = formik;
    let settlementAccountSetupPayload = {
      reference_id: randomUUID(),
      action: values?.action?.label,
      consumer_urn: values.consumer_urn,
      account_number: values.account_number,
      ifsc: values.ifsc.toUpperCase(),
      name: values.name,
      provider_code: "yesb",
      is_master_settlement_account: values.is_master_settlement_account,
    };

    if (values?.action?.label === "DEACTIVATE") {
      settlementAccountSetupPayload = {
        reference_id: randomUUID(),
        action: values?.action?.label,
        consumer_urn: values.consumer_urn,
        settlement_account_urn: values.settlement_account_urn,
        provider_code: "yesb",
      };
    }

    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.baseUrl +
        paAPIEndpoints.SETTLEMENT_ACCOUNT_SETUP.endpoint,
      settlementAccountSetupPayload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setResponseDetails(response.data);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Success"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponseDetails(true);

        // * Remove configure block
        setShowValidateResponseDetails(false);

        formik.resetForm();
      })
      .catch((error) => {
        console.log(
          "🚀 🚀 🚀 ~ file: SettlementAccount.js:188 ~ settlementAccountAction ~ error:",
          error
        );
        setResponseDetails(error?.response?.data);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
        setShowResponseDetails(true);

        // * Remove configure block
        setShowValidateResponseDetails(false);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (values, action) => {
    const payload = {
      grant_type: "admin_credentials",
      email: checkerMail,
      // email,
      google_user_token: google_user_token,
      permission_required: "settlement_approval_check",
      // // Old
      // source: "ADMIN",
      // consent: true,
      // reference_id: randomUUID(),
      // google_user_token,
      // email,
    };

    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      paAPIEndpoints.GENERATE_TOTP.baseUrl + "/v2/auth/token",
      // paAPIEndpoints.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.qr_code) {
            setQrBase(res.data.qr_code);
            setShowTotp(false);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            request_urn: res?.data?.request_urn || null,
          }));
        }
        // Open OTP Modal
        // openTotpModal();
        setShowTotpModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      //* Reset Verified block & Response Block
      setShowValidateResponseDetails(false);
      setShowResponseDetails(false);

      // FIX : workflow api implementation for deactivate
      action.setSubmitting(true);

      // DEACTIVATE
      if (values?.action?.label === "DEACTIVATE") {
        submitHandler();
      }

      let workFlowEndpoint =
        process.env.REACT_APP_ENV_NAME === "production"
          ? "https://workflow.decpay.in/webhook/validate/settlement_account"
          : "https://staging.workflow.decpay.in/webhook/validate/settlement_account";

      // ADD
      if (values?.action?.label === "ADD") {
        APIConfig.API_Client.post(
          workFlowEndpoint,
          {
            account_number: values.account_number,
            ifsc: values.ifsc,
            name: values.name,
          },
          { cancelToken: source.token }
        )
          .then((response) => {
            setValidateResponseDetails(response.data);
            ReactDOM.render(
              <SnackbarMessage
                msgtype="success"
                msg={response?.data?.message || "Success"}
              />,
              document.getElementById("snackbar")
            );

            setShowValidateResponseDetails(true);
          })
          .catch((error) => {
            setValidateResponseDetails(error?.response?.data || error?.message);
            ReactDOM.render(
              <SnackbarMessage
                msgtype="Error"
                msg={error?.response?.data?.message || "Something went wrong!"}
              />,
              document.getElementById("snackbar")
            );
            setShowValidateResponseDetails(false);

            console.log(
              "🚀 🚀 🚀 ~ file: SettlementAccount.js:254 ~ SettlementAccount ~ error:",
              error
            );
          })
          .finally(() => {
            action.setSubmitting(false);
          });
      }
    },
  });

  // Strategic account
  const [isMasterSettlementAccount, setIsMasterSettlementAccount] = useState(
    initialValues.strategic_account
  );

  const handleToggleChange = () => {
    setIsMasterSettlementAccount((prev) => {
      const newIsOn = !prev; // Calculate the new state
      formik.setFieldValue("is_master_settlement_account", newIsOn);
      return newIsOn; // Return the new state
    });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          {/* <div className="ui-divider"></div> */}
          <div className="ui-form-content ui-divider">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="consumer_urn"
                name="consumer_urn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.consumer_urn}
                touched={formik.touched.consumer_urn}
                error={formik.errors.consumer_urn}
                placeholder="Enter consumer URN"
                label="Consumer URN"
                required={true}
                disabled={false}
              />

              <TextFieldSelect
                id="action"
                name="action"
                onChange={(selectedOption) =>
                  formik.setFieldValue("action", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("action", true)}
                value={formik.values.action}
                options={actionDropDownValues}
                noOptionsMessage={() => "No such action exists"}
                label="Action"
                required={true}
                // isLoading={isLoading}
                placeholder="Select action"
                isformatOptionLabel={false}
                isClearable={false}
              />
            </div>

            {formik?.values?.action?.label === "DEACTIVATE" ? (
              <div class="ui-form-inputs-section">
                <TextFieldInput
                  id="settlement_account_urn"
                  name="settlement_account_urn"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.settlement_account_urn}
                  touched={formik.touched.settlement_account_urn}
                  error={formik.errors.settlement_account_urn}
                  placeholder="Enter settlement URN"
                  label="Settlement Account URN"
                  required={true}
                  disabled={false}
                />
              </div>
            ) : null}

            {formik?.values?.action?.label === "ADD" ? (
              <>
                {/* 2 */}
                <div className="ui-form-inputs-section">
                  <TextFieldInput
                    id="account_number"
                    name="account_number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.account_number}
                    touched={formik.touched.account_number}
                    error={formik.errors.account_number}
                    placeholder="Enter account number"
                    label="Account Number"
                    required={true}
                    disabled={false}
                  />

                  <TextFieldInput
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    touched={formik.touched.name}
                    error={formik.errors.name}
                    placeholder="Enter name"
                    label="Name"
                    required={true}
                    disabled={false}
                  />
                </div>
                {/* 3 */}
                <div className="ui-form-inputs-section">
                  <TextFieldInput
                    id="ifsc"
                    name="ifsc"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ifsc.toUpperCase()}
                    touched={formik.touched.ifsc}
                    error={formik.errors.ifsc}
                    placeholder="Enter IFSC"
                    label="IFSC"
                    required={true}
                    disabled={false}
                  />

                  {/* Toggle is settlement Account */}
                  <div
                    className={`ui-form-input-section ui-form-content-input`}
                  >
                    <ToggleCategory
                      // id="is_master_settlement_account"
                      label="Master Settlement Account"
                      isOn={isMasterSettlementAccount}
                      toggleSwitch={handleToggleChange}
                      onBoarding={false}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {!showValidateResponseDetails && (
              <div className="ui-button-container">
                <button
                  className={`formik-btn-submit ${
                    formik.isValid && formik.dirty && !formik.isSubmitting
                      ? "active"
                      : "disabled"
                  }`}
                  type="submit"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting
                    ? "Loading..."
                    : formik?.values?.action?.label === "ADD"
                    ? "Validate"
                    : "Submit"}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          // submitCallback={editNewUser}
          submitAfterTotp={(formik) => settlementAccountAction(formik)}
          formik={formik}
        />
      ) : null}

      {/* Settlement Account API hit block on validate "Success" */}
      {/* {showValidateResponseDetails ? ( */}
      {showValidateResponseDetails ? (
        <VerifiedSettlementAccountBlock
          isLoading={isLoading}
          submitHandler={submitHandler}
        />
      ) : null}

      {/* Settlement account Response Block */}
      {showResponseDetails && (
        <ResponseDetails data={responseDetails} onClick={handleClick} />
      )}
    </>
  );
};

export default SettlementAccount;
