import React from "react";

//styles-import
import "./CopyButton.scss";

//component-import
import copyToClipboard from "../../utilities/copyToClipboard";

const CopyButton = ({ text, className = "ui-copy-button" }) => {
  return (
    <>
      <button className={className} onClick={() => copyToClipboard(text)}>
        <img src="/images/copy-icon.svg" alt="copy icon" />
      </button>
    </>
  );
};

export default CopyButton;
